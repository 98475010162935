<template>
  <div id="addSalesHoursContainer">
    <CCard id="addSalesHoursCard" class="shadow-sm">
      <CCardBody id="addSalesHoursCardBody">
        <CRow id="addSalesHoursHeaderRow">
          <CCol id="addSalesHoursImageCol" sm="1" col="2">
            <img src="/img/icon/store.png" style="width: 32px; height: 32px" />
          </CCol>
          <CCol id="addSalesHoursShopSelectCol" sm="9" col="8">
            <select id="addSalesHoursShopSelect" class="custom-select text-black" v-model="shop" disabled>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>
          </CCol>
          <div
            id="addSalesHoursBackButtonCol"
            class="col-md-2 col-sm-2 col-2 text-right"
            v-if="loadingButton == true"
          >
            <CButton id="addSalesHoursBackButton" block color="light" @click="goback()">
              <span
                ><i
                  class="fi fi-rr-arrow-left mr-2"
                  style="position: relative; top: 2px"
                ></i></span
              >{{ $t("back") }}
            </CButton>
          </div>
          <div id="addSalesHoursLoadingButtonCol" class="col-md-2 col-sm-2 text-right" v-else>
            <CButton id="addSalesHoursLoadingButton" block color="success" disabled>
              <CSpinner id="addSalesHoursLoadingSpinner" color="white" size="sm" />{{ $t("loading") }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>

    <div id="addSalesHoursPermissionContainer" v-if="isPermission">
      <CCard id="addSalesHoursSettingsCard" class="shadow-sm">
        <CCardBody id="addSalesHoursSettingsCardBody" style="padding: 1.55rem">
          <div id="addSalesHoursTitleRow" class="row mb-3">
            <div
              id="addSalesHoursTitleCol"
              class="col-md-10 col-sm-9 col-8 d-inline-flex align-items-center"
            >
              <h2 id="addSalesHoursTitle" class="font-weight-normal">
                {{ $t("addsalesHoursManagement") }}
              </h2>
            </div>
            <div id="addSalesHoursSaveButtonCol" class="col-md-2 col-sm-9 col-4">
              <CButton id="addSalesHoursSaveButton" block color="success" @click="saveSchedule">
                {{ $t("save") }}
              </CButton>
            </div>
          </div>
          <!-- txtsalesHours -->
          <CRow id="addSalesHoursNameRow">
            <CCol id="addSalesHoursNameLabelCol" class="col-form-label" style="margin-top: 2%" lg="2">
              <h6 id="addSalesHoursNameLabel">{{ $t("txtsalesHours") }}</h6>
            </CCol>
            <CCol id="addSalesHoursNameInputCol" style="margin-top: 2%" lg="10">
              <CInput
                id="addSalesHoursNameInput"
                v-if="validateName"
                v-model="name"
                :placeholder="$t('fillSaleName')"
                :is-valid="validator"
                :invalid-feedback="$t('fillSaleName')"
              />
              <CInput
                id="addSalesHoursNameInputFallback"
                v-else
                v-model="name"
                :placeholder="$t('fillSaleName')"
              />
              <span id="addSalesHoursInfoAlert">
                <CAlert
                  id="addSalesHoursInfoAlertBox"
                  color="secondary"
                  class="d-inline-block col-12 mt-0"
                  style="font-size: 14px; padding: 8px 12px"
                >
                  <i class="fi fi-rr-info mr-1 text-warning"></i>
                  {{ $t("บันทึกชื่อช่วงเวลาการขายเพื่อตั้งค่าช่วงเวลาการขาย") }}
                </CAlert>
              </span>
            </CCol>
          </CRow>
          <hr id="addSalesHoursDivider" />
          <h5 id="addSalesHoursSettingTitle" class="mt-4">{{ $t("salesHoursSetting") }}</h5>
          <CAlert
            id="addSalesHoursSettingInfoAlert"
            color="info"
            class="d-inline-block col-12 mt-2"
            style="font-size: 14px; padding: 8px 12px"
          >
            <i class="fi fi-rr-info mr-1"></i>
            {{ $t("txtSalesHoursSetting") }}
          </CAlert>

          <br />

          <div id="addSalesHoursScheduleContainer" class="schedule-container" v-if="isSave">
            <div
              v-for="(day, dayIndex) in days"
              :key="dayIndex"
              class="day-schedule"
              :id="'addSalesHoursDaySchedule' + dayIndex"
            >
              <CRow id="addSalesHoursDayRow" class="justify-content-between m-1">
                <CCol id="addSalesHoursDayLabelCol" col="3" sm="2" md="2" lg="3">
                  <div id="addSalesHoursDayHeader" class="day-header h6">
                    <input id="addSalesHoursDayCheckbox" type="checkbox" v-model="day.enabled" />
                    <span id="addSalesHoursDayName">{{ $t(day.name) }}</span>
                  </div>
                </CCol>
                <CCol id="addSalesHoursDaySlotsCol" col="9" md="8" lg="9" class="text-right">
                  <CRow
                    id="addSalesHoursSlotRow"
                    v-for="(slot, slotIndex) in day.slots"
                    :key="slotIndex"
                    class="justify-content-end m-2"
                  >
                    <CCol id="addSalesHoursSlotStartCol" col sm="4" md="4" lg="3" class="text-right">
                      <vue-timepicker
                        id="addSalesHoursSlotStartPicker"
                        input-class="form-control"
                        v-model="slot.start"
                        :disabled="!day.enabled"
                        :min-time="slot.minTime"
                        :max-time="slot.maxTime"
                      />
                    </CCol>
                    <CCol id="addSalesHoursSlotSeparatorCol" col="1" md="1" lg="1" class="text-center">
                      <span id="addSalesHoursSlotSeparator">-</span>
                    </CCol>
                    <CCol id="addSalesHoursSlotEndCol" col="5" md="4" lg="3" class="text-left">
                      <vue-timepicker
                        id="addSalesHoursSlotEndPicker"
                        input-class="form-control"
                        v-model="slot.end"
                        :disabled="!day.enabled"
                        :min-time="slot.minTime"
                        :max-time="slot.maxTime"
                      />
                    </CCol>
                    <CCol id="addSalesHoursSlotButtonCol" col="1" md="1" lg="1" class="text-right">
                      <button
                        id="addSalesHoursSlotAddButton"
                        class="add-btn"
                        @click="addSlot(dayIndex)"
                        :disabled="!day.enabled || day.slots.length >= maxSlots"
                        v-if="slotIndex === 0"
                      >
                        <img
                          id="addSalesHoursSlotAddIcon"
                          style="width: 25px"
                          src="/img/flat-color-icons--plus.png"
                        />
                      </button>
                      <button
                        id="addSalesHoursSlotDeleteButton"
                        class="delete-btn"
                        @click="removeSlot(dayIndex, slotIndex)"
                        :disabled="!day.enabled"
                        v-else
                      >
                        <img
                          id="addSalesHoursSlotDeleteIcon"
                          src="/img/product/delete32.png"
                          style="width: 30px"
                        />
                      </button>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </div>
          <SchedulePlaceholders id="addSalesHoursSchedulePlaceholders" :animated="animated" v-else />
        </CCardBody>
      </CCard>
    </div>
    <div id="addSalesHoursNoPermissionContainer" v-else>
      <p id="addSalesHoursNoPermissionMessage">{{ $t("noPermission") }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueTimepicker from "vue2-timepicker";
import pos from "@/services/local";
import SchedulePlaceholders from "../../../containers/SchedulePlaceholders.vue";
export default {
  components: {
    VueTimepicker,
    SchedulePlaceholders
  },
  data() {
    return {
      loadingButton: false,
      openingHour: {},
      validateName: "",
      name: "",
      maxSlots: 3, // Max number of slots allowed per day
      isSave : false,
      animated : false,
      days: [
        { name: "monday", enabled: true, slots: [] },
        { name: "tuesday", enabled: true, slots: [] },
        { name: "wednesday", enabled: true, slots: [] },
        { name: "thursday", enabled: true, slots: [] },
        { name: "friday", enabled: true, slots: [] },
        { name: "saturday", enabled: true, slots: [] },
        { name: "sunday", enabled: true, slots: [] },
      ],
    };
  },
  created() {
    this.getManageSaleHours();
  },
  computed: {
    ...mapGetters(["shops", "users", "isOwner"]),
    isPermission() {
      return true;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    shop() {
      return this.shops.find((i) => i.objectId === this.shopObjectId);
    },
  },
  methods: {
    handlePopup() {
      if (this.name == "") {
        this.validateName = "กรุณากรอกชื่อตัวเลือก";
      } else {
        this.validateName = "";
        this.savedata();
      }
    },
    getManageSaleHours() {
      const shopObjectId = `${localStorage.getItem("shopObjectId")}`;
      let params = {
        shopObjectId: shopObjectId,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };
      pos({
        url: "/api/v3.0/shop/ordershopsetting",
        params: params,
        headers: headers,
        method: "get",
      })
        .then((res) => {
         
          if (res.status == 200) {
            let isGrabOrderEnabled = res.data.data.isGrabOrderEnabled;
            let openingHour = res.data.data.grab.openingHour;
            if (
              isGrabOrderEnabled !== undefined &&
              isGrabOrderEnabled !== null &&
              isGrabOrderEnabled !== ""
            ) {
              this.isGrabOrderEnabled = isGrabOrderEnabled;
            } else {
              this.isGrabOrderEnabled = false;
            }

            if (
              openingHour !== undefined &&
              openingHour !== null &&
              openingHour !== ""
            ) {
              this.openingHour = openingHour;
            } else {
              this.openingHour = {};
            }
          } else {
            this.isGrabOrderEnabled = false;
          }
          this.loadingButton = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validator(val) {
      return val ? val.length >= 1 : false;
    },
    goback() {
        this.$router.push({
          name: 'MangeSalesHours',
        });
    },
    addSlot(dayIndex) {
      if (this.days[dayIndex].slots.length < this.maxSlots) {
        const minTime = this.days[dayIndex].slots[0]?.minTime || "08:30";
        const maxTime = this.days[dayIndex].slots[0]?.maxTime || "21:30";
        this.days[dayIndex].slots.push({
          start: minTime,
          end: maxTime,
          minTime,
          maxTime,
        });
      } else {
        alert(this.$t("maxSlotsReached")); // Show alert if max slots are reached
      }
    },
    removeSlot(dayIndex, slotIndex) {
      this.days[dayIndex].slots.splice(slotIndex, 1);
    },
    saveSchedule() {
      const isValid = this.validateSchedule();
      if (!isValid) {
        alert(this.$t("invalidSchedule")); // Add a localized error message
        return;
      }
      // Save logic here
      alert(this.$t("scheduleSaved"));
    },
    validateSchedule() {
      return this.days.every((day) =>
        day.slots.every(
          (slot) =>
            slot.start !== "" &&
            slot.end !== "" &&
            new Date(`1970-01-01T${slot.start}:00Z`) <
              new Date(`1970-01-01T${slot.end}:00Z`)
        )
      );
    },
    mapOpeningHoursToDays() {},
  },
};
</script>

<style scoped>
.schedule-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.day-schedule {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.day-header {
  display: flex;
  align-items: center;
  gap: 10px;
  /* font-size: 18px; */
  font-weight: bold;
}

.add-btn {
  background: none;
  border: none;
  color: green;
  font-size: 18px;
  cursor: pointer;
}

.delete-btn {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
}
/* .placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
} */
.vue-content-placeholders-text__line:nth-child(4n + 1) {
    width: 100%;
}

.vue-content-placeholders-text__line {
    position: relative;
    overflow: hidden;
    height: 15px;
    background: #eee;
    width: 100%;
    margin-bottom: 10px;
}
</style>
