<template>
  <div>
    <div class="schedule-container">
      <div
        v-for="(day, dayIndex) in days"
        :key="dayIndex"
        class="day-schedule"
        :id="'day-schedule-' + dayIndex"
      >
        <CRow class="justify-content-between m-1">
          <CCol col="3" sm="2" md="2" lg="3">
            <content-placeholders :animated="animated">
              <content-placeholders-img
                style="width: 100%; height: 30px; border-radius: 5px"
              >
              </content-placeholders-img>
            </content-placeholders>
          </CCol>
          <CCol col="9" md="8" lg="9" class="text-right">
            <CRow
              v-for="(slot, slotIndex) in day.slots"
              :key="slotIndex"
              class="justify-content-end m-2"
            >
              <CCol col sm="4" md="4" lg="3" class="text-right">
                <content-placeholders :animated="animated">
                  <content-placeholders-img
                    style="width: 100%; height: 30px; border-radius: 5px"
                  >
                  </content-placeholders-img>
                </content-placeholders>
              </CCol>
              <CCol col="1" md="1" lg="1" class="text-center">
                <span>-</span>
              </CCol>
              <CCol col="5" md="4" lg="3" class="text-left">
                <content-placeholders :animated="animated">
                  <content-placeholders-img
                    style="width: 100%; height: 30px; border-radius: 5px"
                  >
                  </content-placeholders-img>
                </content-placeholders>
              </CCol>
              <CCol col="1" md="1" lg="1" class="text-right">
                <content-placeholders :animated="animated">
                  <content-placeholders-img
                    style="width: 25px; height: 25px; border-radius: 50%"
                  >
                  </content-placeholders-img>
                </content-placeholders>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    animated: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingButton: false,
      openingHour: {},
      validateName: "",
      name: "",
      maxSlots: 3, // Max number of slots allowed per day
      days: [
        {
          name: "monday",
          enabled: true,
          slots: [{ start: "09:30", end: "21:30" }],
        },
        {
          name: "tuesday",
          enabled: true,
          slots: [{ start: "09:30", end: "12:30" }],
        },
        {
          name: "wednesday",
          enabled: true,
          slots: [{ start: "21:00", end: "21:30" }],
        },
        {
          name: "thursday",
          enabled: true,
          slots: [{ start: "09:30", end: "21:30" }],
        },
        // {
        //   name: "friday",
        //   enabled: true,
        //   slots: [{ start: "09:30", end: "21:30" }],
        // },
        // {
        //   name: "saturday",
        //   enabled: true,
        //   slots: [{ start: "09:30", end: "21:30" }],
        // },
        // {
        //   name: "sunday",
        //   enabled: true,
        //   slots: [{ start: "09:30", end: "21:30" }],
        // },
      ],
    };
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style scoped>
.schedule-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.day-schedule {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.day-header {
  display: flex;
  align-items: center;
  gap: 10px;
  /* font-size: 18px; */
  font-weight: bold;
}

.add-btn {
  background: none;
  border: none;
  color: green;
  font-size: 18px;
  cursor: pointer;
}

.delete-btn {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
}
/* .placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
  }
  .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;
  } */
.vue-content-placeholders-text__line:nth-child(4n + 1) {
  width: 100%;
}

.vue-content-placeholders-text__line {
  position: relative;
  overflow: hidden;
  height: 15px;
  background: #eee;
  width: 100%;
  margin-bottom: 10px;
}
</style>
